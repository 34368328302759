import React from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { useEtc } from "../context/EtcContext";

const FoodContent = () => {
  const { i18n } = useTranslation();
  const {
    state: { foodData },
  } = useEtc();
  return (
    <FoodDiv>
      <div>
        <h3>-{i18n.language === "en" ? "STARTERS" : "ENTRÉES"}-</h3>
        {foodData?.map(
          (el, ind) =>
            el?.category === "STARTERS" && (
              <div
                key={Math.random() * 140000}
                // className={menuItemId === el.id ? "active-item" : undefined}
              >
                <span>
                  <span>{i18n.language === "en" ? el?.name : el?.name_fr}</span>
                  <p>
                    {i18n.language === "en"
                      ? el?.description
                      : el?.description_fr}
                  </p>
                </span>
                <span>
                  {i18n.language === "en" ? `$${el?.price}` : `${el?.price}$`}
                </span>
              </div>
            )
        )}
        {/* <h3>
          -
          {i18n.language === "en"
            ? "CHARCUTERIE & CHEESE"
            : "CHARCUTERIES ET FROMAGES"}
          -
        </h3>
        {foodData?.map(
          (el, ind) =>
            el?.category === "CHARCUTERIE & CHEESE" && (
              <div
                key={Math.random() * 140000}
                // className={menuItemId === el.id ? "active-item" : undefined}
              >
                <span>
                  <span>{i18n.language === "en" ? el?.name : el?.name_fr}</span>
                  <p>
                    {i18n.language === "en"
                      ? el?.description
                      : el?.description_fr}
                  </p>
                </span>
                <span>
                  {i18n.language === "en" ? `$${el?.price}` : `${el?.price}$`}
                </span>
              </div>
            )
        )} */}
        <h3>-{i18n.language === "en" ? "RAW BAR" : "BAR CRU"}-</h3>
        {foodData?.map(
          (el, ind) =>
            el?.category === "RAW BAR" && (
              <div
                key={Math.random() * 140000}
                // className={menuItemId === el.id ? "active-item" : undefined}
              >
                <span>
                  <span>{i18n.language === "en" ? el?.name : el?.name_fr}</span>
                  {el?.additional &&
                    (i18n.language === "en"
                      ? el?.additional.split(",").map((item) => <p>{item}</p>)
                      : el?.additional_fr
                          .split(",")
                          .map((item) => <p>{item}</p>))}
                  <p>
                    {i18n.language === "en"
                      ? el?.description
                      : el?.description_fr}
                  </p>
                </span>
                <span>
                  {i18n.language === "en" ? `$${el?.price}` : `${el?.price}$`}
                  {el?.additional_price &&
                    el?.additional_price
                      ?.split(",")
                      ?.map((item) => (
                        <div>
                          {i18n.language === "en"
                            ? `$${item.trim()}`
                            : `${item.trim()}$`}
                        </div>
                      ))}
                </span>
              </div>
            )
        )}
        <h3>-{i18n.language === "en" ? "CAVIAR" : "CAVIAR"}-</h3>
        {foodData?.map(
          (el, ind) =>
            el?.category === "CAVIAR" && (
              <div
                key={Math.random() * 140000}
                // className={menuItemId === el.id ? "active-item" : undefined}
              >
                <span>
                  <span>{i18n.language === "en" ? el?.name : el?.name_fr}</span>
                  <p>
                    {i18n.language === "en"
                      ? el?.description
                      : el?.description_fr}
                  </p>
                </span>
                <span>
                  {i18n.language === "en" ? `$${el?.price}` : `${el?.price}$`}
                </span>
              </div>
            )
        )}
        <h3>-{i18n.language === "en" ? "MAINS" : "PLATS PRINCIPAUX"}-</h3>
        <div>
          {i18n.language === "en"
            ? "All mains are served with a side of confit cocktail tomato, grilled Cipollini onion and charred peppers."
            : "Tous les plats principaux sont accompagnés de tomates cocktail confites, d'oignons Cipollini et de poivrons grillés."}
        </div>
        {foodData?.map(
          (el, ind) =>
            el?.category === "MAINS" && (
              <div
                key={Math.random() * 140000}
                // className={menuItemId === el.id ? "active-item" : undefined}
              >
                <span>
                  <span>{i18n.language === "en" ? el?.name : el?.name_fr}</span>
                  <p>
                    {i18n.language === "en"
                      ? el?.description
                      : el?.description_fr}
                  </p>
                </span>
                <span>
                  {i18n.language === "en" ? `$${el?.price}` : `${el?.price}$`}
                </span>
              </div>
            )
        )}
        <h3>-{i18n.language === "en" ? "STEAKS" : "STEAKS"}-</h3>
        <div>
          {i18n.language === "en"
            ? "All grilled meats are served with a side of confit cocktail tomato, grilled Cipollini onion and charred peppers."
            : "Toutes les viandes grillées sont accompagnées de tomates cocktail confites, d'oignons Cipollini et de poivrons grillés."}
        </div>
        {foodData?.map(
          (el, ind) =>
            el?.category === "STEAKS" && (
              <div
                key={Math.random() * 140000}
                // className={menuItemId === el.id ? "active-item" : undefined}
              >
                <span>
                  <span>{i18n.language === "en" ? el?.name : el?.name_fr}</span>
                  {el?.additional &&
                    (i18n.language === "en" ? (
                      <p>{el?.additional}</p>
                    ) : (
                      <p>{el?.additional_fr}</p>
                    ))}
                  <p>
                    {i18n.language === "en"
                      ? el?.description
                      : el?.description_fr}
                  </p>
                </span>
                <div>
                  <div>
                    {i18n.language === "en" ? `$${el?.price}` : `${el?.price}$`}
                  </div>
                  {el?.additional_price && (
                    <div>
                      {i18n.language === "en"
                        ? `$${el?.additional_price}`
                        : `${el?.additional_price}$`}
                    </div>
                  )}
                </div>
              </div>
            )
        )}
        <h3>
          -{i18n.language === "en" ? "HIGH STEAKS" : "COUPES À PARTAGER"}-
        </h3>
        <div>
          {i18n.language === "en"
            ? "All large cuts are served with a side of confit cocktail tomatoes, grilled Cipollini onions and charred peppers with your choice of 2 sauces."
            : "Toutes les pièces de grande taille sont accompagnées de tomates cocktail confites, d'oignons Cipollini et de poivrons grillés, ainsi que de deux sauces au choix."}
        </div>
        {foodData?.map(
          (el, ind) =>
            el?.category === "HIGH STEAKS" && (
              <div
                key={Math.random() * 140000}
                // className={menuItemId === el.id ? "active-item" : undefined}
              >
                <span>
                  <span>{i18n.language === "en" ? el?.name : el?.name_fr}</span>
                  {el?.additional &&
                    (i18n.language === "en" ? (
                      <p>{el?.additional}</p>
                    ) : (
                      <p>{el?.additionalFr}</p>
                    ))}
                  <p>
                    {i18n.language === "en"
                      ? el?.description
                      : el?.description_fr}
                  </p>
                </span>
                <div>
                  <div>
                    {i18n.language === "en" ? `$${el?.price}` : `${el?.price}$`}
                  </div>
                  {el?.additional_price && (
                    <div>
                      {i18n.language === "en"
                        ? el?.additional_price
                        : el?.additional_price_fr}
                    </div>
                  )}
                </div>
              </div>
            )
        )}
        <h3>-{i18n.language === "en" ? "EXTRAS" : "SUPPLÉMENTS"}-</h3>
        {foodData?.map(
          (el, ind) =>
            el?.category === "EXTRAS" && (
              <div
                key={Math.random() * 140000}
                // className={menuItemId === el.id ? "active-item" : undefined}
              >
                <span>
                  <span>{i18n.language === "en" ? el?.name : el?.name_fr}</span>
                  <p>
                    {i18n.language === "en"
                      ? el?.description
                      : el?.description_fr}
                  </p>
                </span>
                <span>
                  {i18n.language === "en" ? `$${el?.price}` : `${el?.price}$`}
                </span>
              </div>
            )
        )}
        <h3>-{i18n.language === "en" ? "SIDES" : "ACCOMPAGNEMENTS"}-</h3>
        {foodData?.map(
          (el, ind) =>
            el?.category === "SIDES" && (
              <div
                key={Math.random() * 140000}
                // className={menuItemId === el.id ? "active-item" : undefined}
              >
                <span>
                  <span>{i18n.language === "en" ? el?.name : el?.name_fr}</span>
                  <p>
                    {i18n.language === "en"
                      ? el?.description
                      : el?.description_fr}
                  </p>
                </span>
                <span>
                  {i18n.language === "en" ? `$${el?.price}` : `${el?.price}$`}
                </span>
              </div>
            )
        )}
        <h3>-{i18n.language === "en" ? "SAUCES" : "SAUCES"}-</h3>
        {foodData?.map(
          (el, ind) =>
            el?.category === "SAUCES" && (
              <div
                key={Math.random() * 140000}
                // className={menuItemId === el.id ? "active-item" : undefined}
              >
                <span>
                  <span>{i18n.language === "en" ? el?.name : el?.name_fr}</span>
                  <p>
                    {i18n.language === "en"
                      ? el?.description
                      : el?.description_fr}
                  </p>
                </span>
                <span>
                  {i18n.language === "en" ? `$${el?.price}` : `${el?.price}$`}
                </span>
              </div>
            )
        )}
        <h3>-{i18n.language === "en" ? "DESSERTS" : "DESSERTS"}-</h3>
        {foodData?.map(
          (el, ind) =>
            el?.category === "DESSERTS" && (
              <div
                key={Math.random() * 140000}
                // className={menuItemId === el.id ? "active-item" : undefined}
              >
                <span>
                  <span>{i18n.language === "en" ? el?.name : el?.name_fr}</span>
                  <p>
                    {i18n.language === "en"
                      ? el?.description
                      : el?.description_fr}
                  </p>
                </span>
                <span>
                  {i18n.language === "en" ? `$${el?.price}` : `${el?.price}$`}
                </span>
              </div>
            )
        )}
        <div>
          <a
            href={`/pdf/etc-main-menu-8.5x13-${i18n.language}.pdf`}
            rel="noopener noreferrer"
            download
          >
            {i18n.language === "en"
              ? "Download the menu"
              : "Télécharger le menu"}
          </a>
          {/* <a
            href={`/pdf/valentines-day.pdf`}
            rel="noopener noreferrer"
            target="_blank"
          >
            {i18n.language === "en" ? "Valentines" : "Saint-Valentin"}
          </a> */}
        </div>
      </div>
    </FoodDiv>
  );
};
const FoodDiv = styled.div`
  height: 100%;
  /* max-height: 580px; */
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 40px;
  & > div {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 5px;
    margin-bottom: 35px;
    overflow-y: scroll;
    scroll-behavior: smooth;
    ::-webkit-scrollbar {
      width: 2px;
    }
    /* Track */
    ::-webkit-scrollbar-track {
      border-radius: 20px;
    }
    /* Handle */
    ::-webkit-scrollbar-thumb {
      background: rgba(255, 255, 255, 0.3);
      border-radius: 20px;
    }
    /* Handle on hover */
    /* ::-webkit-scrollbar-thumb:hover {
    background: var(--lightgrey);
  } */
    & > h3 {
      text-align: center;
      font-weight: 500;
      margin-top: 36px;
      margin-bottom: 24px;
      font-size: 36px;
    }
    & > div {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-start;
      /* cursor: pointer; */
      padding: 16px 8px;
      margin-right: 8px;
      transition: 0.4s all;
      gap: 5px;
      & > span {
        /* max-width: 200px; */
        & > div {
          margin-top: 10px;
        }
        & > span {
          font-size: 16px;
          color: var(--white);
          text-transform: uppercase;
          & > div {
            display: none;
          }
        }
        & > p {
          margin-top: 10px;
          font-weight: lighter;
          font-size: 16px;
          text-transform: capitalize;
        }
      }
      & > div {
        & > div:last-child {
          margin-top: 10px;
        }
      }
    }
    & > a {
      margin-top: 36px;
      align-self: center;
      text-align: center;
      border: none;
      padding: 10px 25px;
      font-size: 18px;
      color: var(--white);
      border: 1px solid var(--white);
      background: none;
      outline: none;
      border-radius: 20px;
      transition: opacity 0.4s ease-in;
      &:hover {
        transition: all 0.3s ease-in;
        background: var(--white);
        color: var(--black);
      }
    }
    & > div:last-child {
      justify-content: center;
      flex-wrap: wrap;
      align-items: center;
      gap: 10px;
      margin-top: 36px;
      & > a {
        align-self: center;
        text-align: center;
        border: none;
        padding: 10px 25px;
        font-size: 18px;
        color: var(--white);
        border: 1px solid var(--white);
        background: none;
        outline: none;
        border-radius: 20px;
        transition: opacity 0.4s ease-in;
        &:hover {
          transition: all 0.3s ease-in;
          background: var(--white);
          color: var(--black);
        }
      }
    }
    /* & > a:first-child {
      align-self: flex-start;
    } */
    /* .active-item {
      background: var(--secondary);
      border-radius: 10px;
    } */
  }
  @media screen and (max-width: 991px) {
    /* start of phone and tablet styles */
    padding: 0;
    & > div {
      /* max-height: 220px; */
      & > h3 {
        font-size: 28px;
        margin-top: 40px;
        margin-bottom: 28px;
      }
      & > div {
        padding: 6px 0;
        margin: 0;
        & > span {
          & > span {
            font-size: 16px;
          }
          & > p {
            font-size: 14px;
          }
        }
      }
    }
  }
`;
export default FoodContent;
