import React, { useState, useEffect } from "react";
import styled, { keyframes, css } from "styled-components";
// import { v4 as uuidv4 } from "uuid";
import { useEtc } from "../context/EtcContext";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { changeLanguage } from "../i18n";

// const links = [
//   "navbar.menu1",
//   "navbar.menu2",
//   "navbar.menu3",
//   "navbar.menu4",
//   "navbar.menu5",
//   "navbar.menu6",
// ];
const Navbar = () => {
  const {
    dispatch,
    state: { menuToggle },
  } = useEtc();
  const [isScrolling, setIsScrolling] = useState(false);
  const { t, i18n } = useTranslation();
  const changeBackground = () => {
    if (window.scrollY >= 66) {
      setIsScrolling(true);
    } else {
      setIsScrolling(false);
    }
  };
  useEffect(() => {
    changeBackground();
    // adding the event when scroll change background
    window.addEventListener("scroll", changeBackground);
    return () => window.removeEventListener("scroll", changeBackground);
  }, []);
  useEffect(() => {
    // Retrieve the language from storage on component mount
    const storedLanguage = localStorage.getItem("language");
    if (storedLanguage) {
      i18n.changeLanguage(storedLanguage);
    }
  }, [i18n]);
  // Set the language in browser storage when it changes
  const handleLanguageChange = (lng) => {
    changeLanguage(lng);
  };
  return (
    <NavbarDiv open={{ menuToggle, isScrolling }}>
      <div>
        <ul>
          {/* {links.map((el, ind) => (
            <li key={uuidv4()}>
              <a
                href={ind === 5 ? "#book-now" : `/#${t(`section${ind + 1}`)}`}
                onClick={(e) =>
                  dispatch({ type: "menu-toggle", payload: false })
                }
              >
                {t(el)}
              </a>
            </li>
          ))} */}
          <li>
            <a
              href={`/#${t(`section${1}`)}`}
              onClick={(e) => dispatch({ type: "menu-toggle", payload: false })}
            >
              {t("navbar.menu1")}
            </a>
          </li>
          <li>
            <a
              href={`/#${t(`section${2}`)}`}
              onClick={(e) => dispatch({ type: "menu-toggle", payload: false })}
            >
              {t("navbar.menu2")}
            </a>
          </li>
          <li>
            <a
              href={"/menu"}
              onClick={(e) => dispatch({ type: "menu-toggle", payload: false })}
            >
              {t("navbar.menu3")}
            </a>
          </li>
          {/* <li>
            <a
              href={`/pdf/valentines-day.pdf`}
              rel="noopener noreferrer"
              target="_blank"
              onClick={(e) => dispatch({ type: "menu-toggle", payload: false })}
            >
              {i18n.language === "en" ? "Valentines" : "Saint-Valentin"}
            </a>
          </li> */}
          <li>
            <a
              href={`/#${t(`section${4}`)}`}
              onClick={(e) => dispatch({ type: "menu-toggle", payload: false })}
            >
              {t("navbar.menu4")}
            </a>
          </li>
          <li>
            <a
              href={`/#${t(`section${5}`)}`}
              onClick={(e) => dispatch({ type: "menu-toggle", payload: false })}
            >
              {t("navbar.menu5")}
            </a>
          </li>
          <li>
            <a
              href={`/#${t(`section${6}`)}`}
              onClick={(e) => dispatch({ type: "menu-toggle", payload: false })}
            >
              {t("navbar.menu6")}
            </a>
          </li>
          <li>
            <a
              href="https://widgets.libroreserve.com/WEB/QC016855598778/book"
              target="_blank"
              rel="noreferrer"
              onClick={(e) => dispatch({ type: "menu-toggle", payload: false })}
            >
              {t("navbar.menu7")}
            </a>
          </li>
          <li
            onClick={() => {
              handleLanguageChange(i18n.language === "en" ? "fr" : "en");
              dispatch({ type: "menu-toggle", payload: false });
            }}
          >
            <Link>{i18n.language === "en" ? "fr" : "en"}</Link>
          </li>
        </ul>
        <div>
          <img src={"/img/ETC_ele_light_02.webp"} alt="element-one" />
        </div>
        <div>
          <img src={"/img/ETC_ele_light_01.webp"} alt="element-two" />
        </div>
      </div>
      <nav>
        <StyledBurger
          key={menuToggle}
          aria-label="hamburger"
          open={menuToggle}
          onClick={() => {
            menuToggle === true
              ? dispatch({ type: "menu-toggle", payload: false })
              : dispatch({ type: "menu-toggle", payload: true });
          }}
        >
          {" "}
          <div />
          <div />
          <div />
        </StyledBurger>
        <BookNow
          href="https://widgets.libroreserve.com/WEB/QC016855598778/book"
          target="_blank"
        >
          {t("navbar.book")}
        </BookNow>
      </nav>
    </NavbarDiv>
  );
};
const slide = keyframes`
from {
    transform: translateY(-100%);
  }
  to {
    transform: translateY(0%);
  }
`;
const slideBack = keyframes`
from {
    transform: translateY(0%);
  }
  to {
    transform: translateY(-100%);
  }
`;
const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;
const rotateRight = keyframes`
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
`;
const rotateLeft = keyframes`
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(-360deg);
  }
`;
// & > a {
//   & > img {
//     height: 100%;
//     width: 100%;
//     position: fixed;
//     max-width: ${({ open }) => (!open.isScrolling ? "145px" : "480px")};
//     max-height: ${({ open }) => (!open.isScrolling ? "75px" : "206px")};
//     left: 50%;
//     top: ${({ open }) => (!open.isScrolling ? "50px" : "45%")};
//     transform: translate(-50%, -55%);
//     transition: all 0.3s ease;
//   }
// }
const NavbarDiv = styled.div`
  position: fixed;
  height: auto;
  width: 100vw;
  z-index: ${({ open }) => (open.menuToggle ? "12" : "11")};
  background: ${(props) =>
    props.open.isScrolling === true ? "rgba(0,0,0,0.4)" : "inherit"};
  & > div:first-child {
    transition: all 0.4s;
    opacity: ${({ open }) => (open.menuToggle ? "1" : "0")};
    z-index: ${({ open }) => (open.menuToggle ? "10" : "-1")};
    pointer-events: ${({ open }) => !open.menuToggle && "none"};
    position: absolute;
    width: 100vw;
    height: 100vh;
    animation: ${({ open }) =>
      open.menuToggle === true
        ? css`
            ${slide} 0.3s ease-in-out forwards
          `
        : css`
            ${slideBack} 0.3s ease-in-out backwards
          `};
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    & > ul {
      position: absolute;
      height: 100vh;
      width: 100vw;
      display: flex;
      gap: 30px;
      background: var(--primary);
      list-style: none;
      padding: 0;
      margin: 0;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      & > li {
        z-index: 11;
        text-transform: lowercase;
        & > a {
          display: block;
          margin: 5px 20px;
          font-size: 2rem;
          color: var(--white);
          text-decoration: none;
          transition: 0.4s scale;
          font-weight: 200;
          &:hover {
            scale: 1.2;
            font-weight: 500;
          }
        }
      }
      & > li:nth-child(8) {
        border-top: 1px solid var(--white);
        padding-top: 48px;
      }
      & > li:nth-child(9) {
        margin-top: 24px;
      }
    }
    & > div:nth-child(2) {
      position: absolute;
      z-index: 2;
      width: 100vw;
      height: 100vh;
      display: flex;
      justify-content: center;
      align-items: center;
      & > img {
        height: auto;
        width: 100%;
        max-width: 700px;
        animation: ${rotateRight} 70s linear infinite;
      }
    }
    & > div:last-child {
      position: absolute;
      z-index: 3;
      width: 100vw;
      height: 100vh;
      display: flex;
      justify-content: center;
      align-items: center;
      & > img {
        height: auto;
        width: 100%;
        max-width: 350px;
        animation: ${rotateLeft} 70s linear infinite;
      }
    }
  }
  & > nav {
    width: 100%;
    height: 80px;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;
    padding: 20px;
    p:nth-child(2) {
      position: relative;
      flex-basis: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      border-left: 2px solid var(--grey);
      font-size: 16px;
      span {
        width: 60px;
        border-bottom: 1px solid var(--grey);
        margin-right: 5px;
        margin-left: 5px;
      }
    }
  }
  @media screen and (max-width: 991px) {
    /* start of phone and table styles */
    & > div:first-child {
      & > ul {
        & > li {
          & > a {
            font-size: 24px;
          }
        }
      }
      & > div:nth-child(2) {
        & > img {
          max-width: 350px;
        }
      }
      & > div:last-child {
        & > img {
          max-width: 170px;
        }
      }
    }
    & > nav {
      padding: 20px;
      background: var(--secondary);
      background: linear-gradient(0deg, var(--primary) 0%, var(--primary) 100%);
    }
  }
`;
const StyledBurger = styled.button`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 35px;
  height: 35px;
  background: transparent;
  border: none;
  font-size: unset;
  padding: 0;
  font-size: unset;
  border-radius: unset;
  cursor: pointer;
  padding: 0;
  z-index: 10;
  &:focus {
    outline: none;
  }
  &:hover {
    background-color: unset;
  }
  & > div {
    width: 34px;
    height: 3px;
    background: var(--white);
    border-radius: 10px;
    position: relative;
    transform-origin: 3px;
    padding-bottom: 2px;
    animation: ${({ open }) => !open && fadeIn} 0.8s ease-in-out;
    :first-child {
      transform: ${({ open }) => (open ? "rotate(45deg)" : "rotate(0)")};
      margin-bottom: 7px;
      align-self: flex-start;
    }
    :nth-child(2) {
      opacity: ${({ open }) => (open ? "0" : "1")};
      transform: ${({ open }) =>
        open ? "translateX(-20px)" : "translateX(0)"};
      margin-bottom: 7px;
      width: 65%;
      align-self: flex-start;
    }
    :nth-child(3) {
      transform: ${({ open }) => (open ? "rotate(-45deg)" : "rotate(0)")};
      width: ${({ open }) => (open ? "100%" : "35%")};
      align-self: flex-start;
    }
  }
`;
const BookNow = styled.a`
  color: var(--white);
  border: 1px solid var(--white);
  padding: 10px 30px;
  cursor: pointer;
  border-radius: 20px;
  font-size: 18px;
  &:hover {
    transition: all 0.3s ease-in;
    background: var(--white);
    color: var(--black);
  }
  @media screen and (max-width: 991px) {
    /* start of phone styles */
    padding: 8px 25px;
  }
`;
export default Navbar;
